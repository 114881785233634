import {Controller} from '@hotwired/stimulus';
import {
    createSectionNew,
    createMediumNew,
    createPort,
    showLinkTools,
    createLeftPorts,
    createRightPorts,
    createLinkElement
} from './multi-stage-helper';
import {GraphicPort, LinkElement, Medium, Section} from './multi-stage-interfaces';
import {shapes} from '@joint/core';
import Rectangle = shapes.standard.Rectangle;
import Link = shapes.standard.Link;

export default class extends Controller {
    static values = {
        interactive: Boolean
    };
    joint: any;
    namespace: any;
    graph: any;
    paper!: any;
    rect!: any;
    form!: any;
    interactiveValue!: boolean;

    async connect() {
        this.joint = await import(/* webpackChunkName: "joint-core" */ '@joint/core');
        this.form = document.forms[0];
        this.namespace = this.joint.shapes;
        this.graph = new this.joint.dia.Graph({}, {cellNamespace: this.namespace});
        this.paper = this.createPaper();

        await this.initializeGraphics()

        this.paper.unfreeze();
    }

    disconnect() {
        super.disconnect();
        this.graph.clear();
        this.paper.remove();
    }

    private async initializeGraphics() {
        const initialData = await this.getInitData();
        const graphicSectionData: Rectangle[] = [];
        initialData.sections.forEach((section: any) => {
            const leftPorts = createLeftPorts();
            const rightPorts = createRightPorts();
            graphicSectionData.push(createSectionNew(section, leftPorts, rightPorts, this.paper.options.height, this.paper.options.width));
        });

        const graphicLinkElementData: Rectangle[] = [];
        initialData.linkElements.forEach((linkElement: any) => {
            console.log(linkElement);
            const linkPortLeft = createPort("F23", linkElement.portList[0].id, "left", -12);
            const linkPortRight = createPort("F24", linkElement.portList[1].id, "right", 0);
            graphicLinkElementData.push(createLinkElement(linkElement, linkPortLeft, linkPortRight))
        });

        let xVal = 0;
        let yValLinks = this.paper.options.height * 0.3 - 150;
        graphicLinkElementData.forEach((rect, index) => {
            const linkEl = initialData.linkElements[index];
            rect.prop('id', linkEl.id);
            rect.prop('internalId', linkEl.internalId);
            rect.prop('type', "linkElement")
            rect.translate(xVal, yValLinks);
            rect.addTo(this.graph);
            xVal += 250;
        });

        xVal = 0;
        graphicSectionData.forEach((rect, index) => {
            const section = initialData.sections[index];
            rect.prop('id', section.id);
            rect.prop('type', "section")
            rect.translate(xVal, 0);
            section.portList.forEach((port: GraphicPort) => {
                const color: any = port.isHotSide ? '#FF0000' : '#0000FF';
                rect.addPort({
                    id: port.id,
                    group: port.side,
                    attrs: {
                        label: {text: port.name},
                        portBody: {fill: color}
                    }
                });
            });
            this.setRectData(rect, this.paper);
            xVal += 300;
        });

        xVal = 0;
        let hotXVal = 0;
        let coldXVal = 0;
        let yVal = this.paper.options.height * 0.3 + 200;
        const links: Link[] = [];
        const media: Rectangle[] = [];
        initialData.sections.forEach((section: any, sectionIndex: number) => {
            let sec: Rectangle = graphicSectionData[sectionIndex];
            if (sectionIndex > 0) {
                // @ts-ignore
                hotXVal = sec.get('position').x - 100;
                // @ts-ignore
                coldXVal = sec.get('position').x - 100;
            }

            section.fluidList.forEach((fluid: Medium) => {
                const port = createPort('', fluid.port.id, fluid.port.side, fluid.port.side == 'left' ? -12 : 0);
                const medium = createMediumNew(fluid, port);
                medium.prop('sectionId', section.id);
                const color: any = fluid.port.isHotSide ? '#FF0000' : '#0000FF';
                medium.addPort({
                    id: fluid.port.id,
                    group: fluid.port.side,
                    attrs: {
                        label: {text: fluid.port.name},
                        portBody: {fill: color}
                    }
                });
                if (fluid.isHotSide) {
                    medium.prop('xAxisValue', hotXVal);
                    hotXVal += 140;
                } else {
                    medium.prop('xAxisValue', coldXVal);
                    coldXVal += 140;
                }


                media.push(medium);
            });
            console.log(section.linkList);
            section.linkList.forEach((link: any) => {
                links.push(this.addLink(link.fromParentId, link.fromPortId, link.toParentId, link.toPortId));
            });
        });

        media.forEach((rect) => {
            if (rect.attributes.id.includes('hot')) {
                yVal = this.paper.options.height * 0.3 - 250;
                rect.translate(rect.prop('xAxisValue'), yVal);
            } else {
                yVal = this.paper.options.height * 0.3 + 200;
                rect.translate(rect.prop('xAxisValue'), yVal);
            }
            rect.addTo(this.graph);
        });

        initialData.linksForSectionsAndLinkElements.forEach((link: any) => {
            console.log(link);
            links.push(this.addLink(link.fromParentId, link.fromPortId, link.toParentId, link.toPortId));
        });

        links.forEach((link) => {
            link.addTo(this.graph);
        });

    }

    private async updatePheMultiStage() {
        const sec: Section = await this.getSectionDataForUpdate();
        this.updateSectionData(sec);
    }

    // rect: shapes.standard.Rectangle, paper: dia.Paper
    private setRectData(rect: any, paper: any) {
        rect.addTo(this.graph);

        paper.on('element:pointerclick', (elementView: any) => {
            const currentElement = elementView.model;
            this.showData(currentElement.prop('type'), currentElement.prop("sectionId"), currentElement.prop("internalId"));
        });

        // Register events
        paper.on('link:mouseenter', (linkView: any) => {
            showLinkTools(linkView);
        });

        paper.on('link:mouseleave', (linkView: any) => {
            linkView.removeTools();
        });
    }

    addLink(sourceId: any, sourcePortId: any, targetId: any, targetPortId: any) {

       return new this.joint.shapes.standard.Link({
            source: {
                id: sourceId,
                port: sourcePortId
            },
            target: {
                id: targetId,
                port: targetPortId
            },
        });

    }

    createPaper() {
        return new this.joint.dia.Paper({
            el: <HTMLElement>this.element,
            model: this.graph,
            width: this.element.clientWidth * 2,
            height: 1000,
            gridSize: 1,
            cellViewNamespace: this.namespace,
            restrictTranslate: true,
            linkPinning: false, // Prevent link being dropped in blank paper area
            defaultLink: () =>
                new this.joint.shapes.standard.Link({
                    attrs: {
                        wrapper: {
                            cursor: 'default'
                        }
                    }
                })
        });
    }

    private showData(type: string, sectionId: string, elementId: string) {

        let link: string;

        switch (type) {
            case "medium":
                link = "/phe/multi_stage_parameter/medium_parameters/" + sectionId + "/" + elementId;
                break;
            case "section":
                link = "/phe/multi_stage_parameter/section_thermal_parameters/" + elementId;
                break;
            case "linkElement":
                link = "/phe/multi_stage_parameter/link_element_parameters/" + elementId;
                break;
            default:
                alert("No valid graphical element");
                return;
        }

        window.dispatchEvent(new CustomEvent("show-off-canvas", {
            bubbles: true,
            detail: {href: link, position: "RIGHT"}
        }));
    }

    private async rebuild() {
        this.graph.clear();
        await this.initializeGraphics();
    }

    private getInitData(): any {
        return fetch('/phe/multi_stage/initialize_new', {
            method: 'GET',
            headers: {'Content-Type': 'application/json;charset=utf-8'}
        })
            .then((response) => {
                return response.json();
            })
            .then((dataObj) => {
                return dataObj;
            });
    }

    private getSectionDataForUpdate(): any {
        return fetch('/phe/multi_stage/section_data', {
            method: 'GET',
            headers: {'Content-Type': 'application/json;charset=utf-8'}
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data;
            });
    }

    private updateSectionData(sectionUpdate: Section) {
        const section: Rectangle = this.graph.getCell(sectionUpdate.id);
        section.attr('hotInTemp/text', Number(sectionUpdate.hotInTemp).toFixed(2));
        section.attr('hotOutTemp/text', Number(sectionUpdate.hotOutTemp).toFixed(2));
        section.attr('coldInTemp/text', Number(sectionUpdate.coldInTemp).toFixed(2));
        section.attr('coldOutTemp/text', Number(sectionUpdate.coldOutTemp).toFixed(2));
        section.attr('hotPressureDrop/text', Number(sectionUpdate.hotPressureDrop).toFixed(3) + ' kPa');
        section.attr('coldPressureDrop/text', Number(sectionUpdate.coldPressureDrop).toFixed(3) + ' kPa');
        section.attr('hotPasses/text', sectionUpdate.hotPasses + ' / ' + sectionUpdate.hotGapsPerPass);
        //section.attr('coldPasses/text', sectionUpdate.coldPasses + ' / ' + sectionUpdate.coldGapsPerPass);
    }
}
